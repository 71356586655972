import React from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form } from "antd";
import { DatePicker } from "../../../Common/components/DatePicker";
import { useFilters } from "../../hooks";

import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import { SelectInput } from "../../../Common/components";
import { setFormInputValue } from "../../../../common/helpers";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";

export function CostsFilters({ fields = null }) {
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();

  return (
    <Filters
      form={form}
      onApply={(values) => updateFilters({ ...filters, ...values })}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="date">
            <DateRangePicker
              onChange={(dates) => {
                setFormInputValue(
                  "date",
                  {
                    from: dates[0],
                    to: dates[1],
                  },
                  form
                );
              }}
            />
          </Form.Item>
          <Form.Item name="page_size" label={_t("show")}>
            <SelectInput
              allowClear={false}
              onDropdownVisibleChange={(open) => !open && form.submit()}
              options={[
                { value: "100", label: "100" },
                { value: "500", label: "500" },
                { value: "1000", label: "1000" },
              ]}
            />
          </Form.Item>
          <Form.Item name="url">
            <Input placeholder={_t("url")} className={"filter-input"} />
          </Form.Item>
          <Form.Item name="game">
            <Input placeholder={_t("game")} className={"filter-input"} />
          </Form.Item>
          <Form.Item name="refcode">
            <Input placeholder={_t("refcode")} className={"filter-input"} />
          </Form.Item>
          {fields}
        </Space>
      }
    />
  );
}
