import React from "react";
import { useHistory } from "react-router-dom";
import { isAuthorized } from "../../../system/helpers/localstorageHelper";
import { Redirect } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import Button from "../../Common/components/Button";
import { Typography } from "antd";
import { useRestorePassword } from "../apiHooks";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import AuthTemplate from "../../Layouts/AuthTemplate";

export const ForgotPassword = () => {
  const history = useHistory();
  const [submit, { isSuccess, isLoading }] = useRestorePassword();

  if (isAuthorized()) {
    return <Redirect to={APP_ROUTES.dashboard.general} replace />;
  }

  const form = (
    <Form onFinish={submit} layout="vertical" className="auth-form">
      <Form.Item
        name="email"
        label={_t("Please enter your email address")}
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
      >
        <Input placeholder={_t("E-mail")} />
      </Form.Item>
      <div className="auth-form__footer">
        <button className="btn" type="submit" disabled={isLoading}>
          {_t("Remind")}
        </button>
        <button
          className="btn btn--second"
          type="submit"
          onClick={() => history.goBack()}
        >
          {_t("Back")}
        </button>
      </div>
    </Form>
  );

  const success = (
    <Typography.Text>
      {_t("Please visit your email and follow the instruction")}
    </Typography.Text>
  );

  return (
    <AuthTemplate title={_t("Reset password")}>
      {isSuccess ? success : form}
    </AuthTemplate>
  );
};
